@import '../../styles/customMediaQueries.css';

.sellerCard {
  background-color: var(--matterColorLight);
  /* border-radius: 20px; */
  /* border: solid 1px var(--matterColorNegative); */
  transition: all ease 0.5s;
  padding: 8px;
  width: 100%;
  /* margin-bottom: 20px;
  margin-right: 20px;
  min-width: calc(70% / 1 - 20px);

  @media (min-width: 414px) {
    width: calc(100% / 2 - 20px);
    min-width: calc(100% / 2 - 20px);
  }

  @media (--viewportSmall) {
    width: calc(100% / 3 - 20px);
    min-width: calc(100% / 3 - 20px);
  }

  @media (--viewportLarge) {
    min-width: auto;
    width: calc(100% / 4 - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
  }

  @media (--viewportMLarge) {
    width: calc(100% / 5 - 40px);
    margin-right: 40px;
    margin-bottom: 40px;
  }

  @media (min-width: (--viewportMedium)) and (min-width: (--viewportLarge)) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: (--viewportLarge)) and (min-width: (--viewportMLarge)) {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media (--viewportMLarge) {
    &:nth-child(5n) {
      margin-right: 0;
      margin-right: 0;
    }
  } */

  &:hover {
    /* border-color: var(--matterColorAnti); */
    transition: all ease 0.5s;
  }

  & .rating {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -12px;
    font-size: 14px;

    & > svg {
      width: 14px;
      margin-right: 5px;

      & > path {
        fill: var(--attentionColor);
      }
    }
  }

  & a {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;

      & .sellerName {
        /* color: var(--marketplaceColor); */
      }

      & .sellerImg {
        & img {
          transition: all ease 0.5s;
          transform: scale(1.05);
        }
      }
    }

    & .sellerImg {
      width: 80%;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      border: solid 1px var(--matterColorNegative);

      & img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all ease 0.5s;
        transform: scale(1);
      }
    }

    & .sellerName {
      font-size: 14px;
      font-weight: 600;
      color: var(--matterColor);
      margin: 0 0 8px 0;
      line-height: 130%;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }

    & .sellerinfo {
      font-size: 12px;
      font-weight: 400;
      color: var(--matterColor);
      margin: 0;
      line-height: 130%;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }
  }
}

.sellerInitials {
}

.initials {
  padding: 62px;
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  position: relative;
  top: 58px;
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    padding: 50px;
  }
}

.sellerNameInit {
  position: relative;
  top: 134px;
  @media screen and (max-width: 768px) {
    top: 120px;
  }
}

.sellerInfoInit {
  position: relative;
  top: 135px;
  font-size: 14px;
  font-weight: 600;
  color: var(--matterColor);
  margin: 0 0 8px 0;
  line-height: 130%;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;

  @media screen and (max-width: 768px) {
    top: 120px;
  }
}
