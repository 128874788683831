@import '../../styles/customMediaQueries.css';

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--matterColorDark);
  margin: 0 0 24px 0;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
  }
  @media (--viewportSmall) {
    font-size: 20px;
  }
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.listingPageSlider {
  & .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--marketplaceColor);
  }
}

.profileSliderButtons {
  position: absolute;
  top: 40%;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & .iconHolder {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer;

    @media (--viewportXXLarge) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      border-color: #484848;
      cursor: pointer;
    }

    &:first-child {
      margin-right: 4px;

      @media (--viewportXXLarge) {
        margin-right: 14px;
      }
    }

    & svg {
      fill: none;
    }
  }
}

.moreServices {
  & .moreServicesTitle {
    margin-bottom: 15px;

    & h1 {
      font-style: normal;
      font-weight: bold;
      color: #1f1f1f;
      font-size: 25px;
      line-height: 100%;
      letter-spacing: -0.04em;
      margin: 0px 0px 16px 0;

      @media (min-width: 480px) {
        font-size: 36.6667px;
        line-height: 100%;
      }

      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 100%;
      }

      @media (min-width: 1260px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width: 1366px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width: 1660px) {
        font-size: 36.8889px;
        line-height: 100%;
      }

      @media (min-width: 1921px) {
        font-size: 56.8956px;
        line-height: 100%;
      }
    }

    & .sliderButtons {
      display: none;
      height: 32px;

      @media (--viewportMedium) {
        display: block;
      }

      & .iconHolder {
        background-color: #fff;
        border: 1px solid #ebebeb;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.5s;

        @media (--viewportXXLarge) {
          width: 56px;
          height: 56px;
        }

        &:hover {
          border-color: #484848;
          cursor: pointer;
        }

        &:first-child {
          margin-right: 4px;

          @media (--viewportXXLarge) {
            margin-right: 14px;
          }
        }

        & svg {
          fill: none;
        }
      }
    }
  }

  & .cardSlider {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 0;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 767px) {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

      & .cardSliderItem {
        min-width: 299px;
        margin-right: 15px;
        scroll-snap-align: start;
      }
    }

    & > div {
      & > div {
        overflow: hidden;
      }
    }

    & > a:first-child {
      padding: 0 0 0 24px;

      @media (--viewportSmall) {
        padding: 0;
      }
    }
  }
}

.moreServicesTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (--viewportSmall) {
    padding: 0;
  }

  & > h1 {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.12px;
  }
}

.cardSlide {
  position: relative;
  /* display: flex; */
  /* & .sliderButtons {
    display: none;
    height: 32px;

    @media (--viewportMedium) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% + 100px);
      z-index: 1;
      display: flex;
      justify-content: space-between;
      margin: 0px -50px;
    }

    & .iconHolder {
      background-color: var(--marketplaceColor);
      border: 1px solid var(--marketplaceColor);
      width: 32px;
      height: 32px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.5s;

      @media (--viewportXXLarge) {
        width: 56px;
        height: 56px;
      }

      &:hover {
        border-color: var(--marketplaceColor);
        cursor: pointer;
      }

      &:first-child {
        margin-right: 4px;

        @media (--viewportXXLarge) {
          margin-right: 14px;
        }
      }

      & svg {
        fill: none;

        &>circle {
          fill: var(--marketplaceColor);
        }

        &>path {
          stroke: var(--matterColorLight);
        }
      }
    }
  } */
}

.tabletHomepage {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  & > a {
    height: 100%;
    background-color: #fff;
  }
}

.homepage {
  margin-right: 15px;
  scroll-snap-align: start;

  @media (max-width: 767px) {
    min-width: calc(100% / 2 - 5%);
  }

  @media (max-width: 480px) {
    min-width: calc(100% / 1 - 11%);
  }

  & > a {
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
  }

  &:last-child {
    margin-right: 0px;

    & > a {
      margin-right: 0px;
    }
  }
}

.authors {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.cardSliderItem {
  &.cardSliderItemMakes {
    & .imgWrapper {
      & > img {
        object-fit: cover;
      }
    }
  }

  & > a {
    display: inline-block;
    width: 100%;
    text-align: center;

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;

      & .imgWrapper {
        transition: all ease 0.5s;

        & > img {
          transform: scale(1.05);
          transition: all ease 0.5s;
        }
      }

      & .cardItemTitle {
        color: var(--marketplaceColor);
      }
    }

    & .imgWrapper {
      height: 250px;
      width: 100%;
      border: solid 1px #ececec;
      overflow: hidden;
      transition: all ease 0.5s;
      border-radius: 10px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all ease 0.5s;
      }
    }

    & .cardItemTitle {
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 0;
      color: #484848;
    }
  }

  &.popularDesignerWrapper {
    @media (max-width: 767px) {
      min-width: 250px !important;
    }

    & .popularDesignerImg {
      width: 100%;
      height: 100%;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.titleHighlight {
  color: var(--marketplaceColor);
}
