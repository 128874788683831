@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.line {
  margin: 8px 0px !important;
}

.lineItem {
  composes: marketplaceH4FontStyles from global;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  composes: marketplaceH5FontStyles from global;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  composes: marketplaceButtonFontStyles from global;
}

.subTotalLineItem {
  composes: marketplaceH4FontStyles from global;
  /* font-weight: var(--fontWeightBold); */
  margin: 0 !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: marketplaceH4FontStyles from global;
  margin: 0 !important;
  padding: 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.itemLabel {
  & img {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    margin-right: 14px;
  }
}

.itemLabelDiscount {
  text-decoration: line-through;
  flex: 1;

  & img {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    margin-right: 14px;
  }
}

.itemValueDiscount {
  text-decoration: line-through;
  margin: 0 0 0 10px;
}

.itemValue {
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0;
  }
}

.totalLabel {
  font-weight: var(--fontWeightBold);
}

.totalPrice {
  composes: marketplaceButtonFontStyles from global;
  font-weight: var(--fontWeightBold);
  margin: 0 0 0 10px !important;
  padding-top: 0px;
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.itemValuePrice {
  margin-top: 20px;
}

.quan {
  position: absolute;
  right: 0;
  left: 98px;
  background: #c6488c;
  color: white;
  text-align: center;
  border-radius: 22px;
  height: 24px;
  width: 24px;
  bottom: 0px;
  top: 183px;
}

.discount {
  color: #c6488c;
}

.transactionBreakdown {
  & .totalDivider {
    display: none;
    &:last-of-type {
      display: block;
      background-color: transparent;
      border-bottom: dashed 2px var(--matterColorNegative);
    }
  }
  & .subTotalLineItem {
    padding-top: 0;
  }
  & .itemLabel,
  & .itemValue,
  & .totalLabel,
  & .totalPrice {
    font-size: 14px;
  }
}
