@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);

  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    display: block;
  }

  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 100% !important;
    width: 100%;
    min-height: calc(100vh - 252px);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }
  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & :global(.DateInput) {
      width: calc(100% / 2 - 12px);
      & :global(.DateInput_input) {
        border: solid 1px var(--matterColorAnti);
        border-radius: 6px;
        padding: 0 15px;
        font-size: 15px;
        font-weight: var(--fontWeightRegular);
      }
    }
  }
  & :global(.DateInput_input) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    background: none;
  }
  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    box-shadow: none;
  }

  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.DateRangePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px 3px 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: calc(50% - 6px);
    background: none;

    @media (--viewportMedium) {
      width: calc(50% - 12px);
    }
  }

  & :global(.DateInput:first-of-type) {
    margin-right: 12px;

    @media (--viewportMedium) {
      margin-right: 24px;
    }
  }
}

.arrowIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}
