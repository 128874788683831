@import '../../styles/customMediaQueries.css';

.root {
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    /* TODO: Make sure button text aligns with the baseline */
  }

  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  /* Clear padding that is set for link elements looking like buttons */
}

.primaryButtonRoot {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorDark);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
  }

  /* Clear padding that is set for link elements looking like buttons */
}

.secondaryButtonRoot {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  background-color: var(--matterColorLight);
  color: var(--matterColor);

  /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 10px 20px;

  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  transition: all ease 0.5s;

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    border-color: var(--matterColorAnti);
    transition: all ease 0.5s;
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    border-color: var(--matterColorNegative);
    transition: all ease 0.5s;
  }
}

.inlineTextButtonRoot {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 10px 20px;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.primaryButton {
  padding: 10px 20px;
  /* Class handle for primary button state styles */
}

.secondaryButton {
  padding: 10px 20px;
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 10px 20px;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    /* TODO: Make sure button text aligns with the baseline */
  }

  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--matterColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  min-height: 48px;
  background-color: var(--matterColorLight);

  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  border: 1px solid #d2d2d2;
  border-radius: 4px;

  /* We need to add this to position the icon inside button */
  position: relative;

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
  }
}

.socialButton {
}
