@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
/* Aspect ratio for is given inline */
.aspectPadding {
}

.aspectBox {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.profileCard {
  width: 100%;
  height: 400px;

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 300px !important;
  }

  & .aspectPadding {
    padding-bottom: 0 !important;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .aspectBox {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
