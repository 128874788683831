@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--matterColorLight);
  /* border-bottom: 1px solid #f2f4f7; */
  padding: 4px 16px;

  @media (--viewportLarge) {
    padding: 4px 24px;
  }
}

.authRoot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  padding: 4px 16px;

  @media (--viewportLarge) {
    padding: 4px 24px;
  }
}

.fixedWidthContainer {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

/* logo */
.logoLink {
  flex-shrink: 0;
  margin-right: 10px;
  height: 100%;

  @media (--viewportLarge) {
    margin-right: 20px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 46px;
  width: 130px;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;

  @media (--viewportLarge) {
    width: 200px;
  }
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  composes: marketplaceH4FontStyles from global;
  composes: TopbarDesktop_label from global;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
}

/* Create listing (CTA for providers) */
.createListingLink {
  font-weight: var(--fontWeightMedium);
  flex-shrink: 0;
  height: 100%;
  padding: 0px;
  color: #303030;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  margin-left: 8px;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-size: 14px;
  }

  @media (--viewportLarge) {
    margin-left: 10px;
    font-size: 14px;
  }

  @media (--viewportMLarge) {
    margin-left: 20px;
    font-size: 15px;
  }

  &:first-of-type,
  &:first-child {
    margin-left: 0;
  }

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .inbox {
    color: #333;
    text-decoration: none;
    position: relative;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}
.createListingSelect {
  border: none;
  color: #4a4a4a;
  font-weight: 600;
  & > option {
    line-height: 24px;
  }
}

.createListing {
  composes: TopbarDesktop_label from global;
  color: #4a4a4a;
  font-weight: 600;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
  width: 34px;
  height: 34px;

  @media (--viewportLarge) {
    width: 40px;
    height: 40px;
  }

  & > span {
    font-size: 12px;

    @media (--viewportLarge) {
      font-size: 14px;
    }
  }
}

.profileMenuContent {
  top: 100%;
  left: auto !important;
  right: 0 !important;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  transition: all 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 20px;
    width: 20px;
    height: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E")
      no-repeat 50% 50%/100% auto;
  }

  & > div {
    display: none;
  }

  & > ul {
    padding-top: 10px;

    & > li {
      & > a,
      & > button {
        & > svg {
          width: 20px;
          margin-right: 15px;
        }
      }

      & .logoutButton {
        & > svg {
          fill: none;
        }
      }
    }
  }
}

/* Authentication */
.signupLink {
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.loginLink {
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signup,
.login {
  composes: marketplaceH5FontStyles from global;
  composes: TopbarDesktop_label from global;
}

.profileSettingsLink,
.yourListingsLink {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 6px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: 0;
  font-size: 14px;
  border-radius: 0;
  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border: none;
  text-decoration: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 125%;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;
}

.rightNav {
  align-items: center;
  display: flex;
}

.searchIcon {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > svg {
    &:hover {
      stroke: var(--marketplaceColor);
    }
  }
}

.leftNav {
  display: flex;
  align-items: center;
}

.searchExpanded {
  display: flex;
  align-items: center;
  max-width: 50%;
  width: 100%;
  background-color: #fcfcfc;
  border: solid 1px #d2cece;
  height: 60px;
  margin: 6px auto;
  padding: 0 20px;
}

.crossIcon {
  font-size: 40px;
  line-height: 15px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.seachIcon {
  & > svg {
    stroke: #333;

    &:hover {
      stroke: var(--marketplaceColor);
    }
  }
}

.cartPostLogin {
  margin-left: 0px;
  cursor: pointer;
  position: relative;

  & .badge {
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);
    font-size: 12px;
    line-height: 16px;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -9px;
  }

  & > svg {
    color: #333;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.menuLink {
  margin-left: 10px;

  @media (--viewportLarge) {
    margin-left: 20px;
  }
}

.iconMessage {
  &:hover {
    & > svg {
      & > path {
        fill: var(--marketplaceColor);
      }
    }
  }

  & > svg {
    & > path {
      fill: #4a4a4a;
    }
  }
}

.cartIcon {
  fill: none;
}

.navLink {
  margin-left: 10px;
  cursor: pointer;
  position: relative;

  @media (--viewportLarge) {
    margin-left: 20px;
  }

  &:hover {
    text-decoration: none;
  }

  & .inbox {
    color: #4a4a4a;
    text-decoration: none;

    &:hover {
      color: var(--marketplaceColor);
    }

    & .notificationDot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: -9px;
      background-color: var(--marketplaceColor);

      animation-name: notificationPop;
      animation-duration: 0.1s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;

      animation-timing-function: ease-in;
      opacity: 0;
      transform: scale(0);
    }
  }

  & .badge {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    font-size: 12px;
    line-height: 16px;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -9px;
  }

  &:first-child {
    margin-left: 0;
  }

  & svg {
    color: #4a4a4a;
    width: 16px;

    @media (--viewportLarge) {
      width: 20px;
    }

    @media (--viewportLarge) {
      width: 25px;
    }

    &:hover {
      color: var(--marketplaceColor);
    }

    &.cartIcon {
      width: 16px;

      @media (--viewportLarge) {
        width: 20px;
      }

      @media (--viewportLarge) {
        width: 25px;
      }
    }
  }
}

.chooseCategory {
  /* width: 60px; */
  & > select {
    color: #303030;
    border: none;
    /* width: 68px; */
  }
}

.iconHeart {
  &:hover {
    & > svg {
      fill: var(--marketplaceColor);
    }
  }

  & > svg {
    fill: #4a4a4a;
  }

  & > img {
    width: 29px;
    filter: opacity(0.5);
  }
}

.buttonLink {
  margin-right: 8px;
}

.notificationDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  right: -10px;
  background-color: var(--marketplaceColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.dashboardHeader {
  padding: 4px 14px 4px 0px;
  @media (--viewportLarge) {
    padding: 4px 24px 4px 0px;
  }
  & .fixedWidthContainer {
    max-width: 100%;

    & .leftNav {
      & .logoLink {
        display: none;
      }
      & .createListingLink {
        margin-left: 5px;
        @media (--viewportLarge) {
          margin-left: 20px;
        }
      }
      & .logoLink + .createListingLink {
        margin-left: 0;
      }
    }
    & .rightNav {
      & .navLink {
        margin-left: 5px;
        @media (--viewportLarge) {
          margin-left: 10px;
        }
      }
    }
  }
}
