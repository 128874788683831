@import '../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 5px;

  & .searchSubmit {
    border: 0;
    padding: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 0;
      outline: none;

      & .iconSvgGroup {
        stroke: var(--matterColor);
      }
    }

    & .mobileIcon {
      display: flex;
      width: 24px;
      margin: 10px 0px 10px 20px;
      background-color: var(--matterColorLight);
    }
  }
}

.mobileInput {
  composes: marketplaceH1FontStyles from global;
  margin: 0;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;
  border: none;
  box-shadow: none;
  font-size: 18px;

  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.desktopInputRoot {
}

.desktopIcon {
  border: none;
  padding-top: 3px;
  display: flex;
  width: 24px;
  align-self: stretch;
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  composes: marketplaceH4FontStyles from global;
  line-height: unset;
  border: none;
  padding-top: 14px;
  padding-bottom: 0;
  padding-left: 20px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: calc(100% + 20px);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
  height: 100%;

  & .searchSubmit {
    border: 0;
    padding: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 0;
      outline: none;

      & .iconSvgGroup {
        stroke: var(--marketplaceColor);
      }
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
