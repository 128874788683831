@import '../../styles/customMediaQueries.css';

.root {
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  padding: 24px;
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 30px #0000001f;
  width: 96%;
  z-index: 1000;
  margin: 0 auto;
  left: 0;
  right: 0;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 400px;
    left: 20px;
    margin: 0;
  }
  @media (max-width: 767px) {
    position: fixed;
    bottom: 107px;
    left: 0px;
    width: 96%;
  }
  @media (max-width: 480px) {
    position: fixed;
    bottom: 87px;
    left: 0px;
    width: 96%;
  }
}
.cookiesContent {
  display: flex;
  flex-direction: column;
  position: relative;
  & .closeCookies {
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 40px;
    line-height: 50%;
    font-weight: 300;
    cursor: pointer;
    color: var(--matterColor);
    &:hover {
      color: var(--marketplaceColor);
    }
  }
  & > h2 {
    font-size: 18px;
    line-height: 130%;
    color: var(--matterColorDark);
    margin: 0 0 10px 0;
  }
  & .message {
    color: var(--matterColor);
    font-size: 15px;
    line-height: 130%;
    margin: 0 0 20px 0;
    & .cookieLink {
      text-decoration: underline;
      color: var(--marketplaceColor);
      &:hover {
        color: var(--matterColorDark);
      }
    }
  }
  & .cookiesBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .continueBtn {
      font-size: 15px;
      line-height: 120%;
      font-weight: var(--fontWeightSemiBold);
      padding: 8px 16px;
      background-color: var(--marketplaceColor);
      color: var(--matterColorLight);
      border: solid 1px var(--marketplaceColor);
      border-radius: 5px;
      cursor: pointer;
      transition: var(--transitionStyleButton);
      &:hover {
        background-color: var(--matterColorDark);
        border: solid 1px var(--matterColorDark);
        color: var(--matterColorLight);
        transition: var(--transitionStyleButton);
      }
    }
    & .cancelBtn {
      font-size: 15px;
      line-height: 120%;
      font-weight: var(--fontWeightSemiBold);
      padding: 8px 16px;
      background-color: var(--matterColor);
      color: var(--matterColorLight);
      border: solid 1px var(--matterColor);
      border-radius: 5px;
      cursor: pointer;
      transition: var(--transitionStyleButton);
      &:hover {
        background-color: var(--matterColorDark);
        border: solid 1px var(--matterColorDark);
        color: var(--matterColorLight);
        transition: var(--transitionStyleButton);
      }
    }
  }
}
