@import '../../styles/customMediaQueries.css';

.root {
}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 10px 15px;
  height: 45px;
  border-radius: 4px;
  padding-right: 20px;
  background-color: var(--matterColorLight);

  & > option {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorNegative);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--matterColorNegative);
}

.selectError {
  border-color: var(--failColor);
}
