@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  --marketplaceColor: #c6488c;
  --marketplaceColorLight: #f2d7d7;
  --marketplaceColorDark: #af3f7b;

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23C6488C;

  --successColor: #59766c;
  --successColorDark: #59766c;
  --successColorLight: #f0fff6;
  --failColor: #ed1b24;
  --failColorLight: #fff0f0;
  --attentionColor: #f77f00;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColor: #4a4a4a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fbfbfb;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'Montserrat', sans-serif;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 72px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

/* ================ Global element styles ================ */

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor);
  /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor);
  /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

h1 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 45px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;

  @media (--viewportMedium) {
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 21px;
    margin-bottom: 19px;
  }
}

h3 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

h4 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

h5 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 15px;
    line-height: 125%;
  }
}

h6 {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 6px;
  }
}

li {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }
}

p,
pre {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

/* ================ Normalisations ================ */

html {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }

  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 15px;
    line-height: 125%;
  }

  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 3px;
  padding-bottom: 5px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

  color: var(--marketplaceColorDark);
  /* Unset user agent styles */
  appearance: none;
  border: solid 1px var(--matterColorNegative);
  padding: 10px 15px;
  height: 45px;

  /* Borders */
  border-radius: 0;

  /* Background */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px;
  background-position: center right 15px;
  background-repeat: no-repeat;
  /* Effects */

  cursor: pointer;
  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    outline: none;
  }

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }

  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 125%;
  }
}

input {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 15px;
  height: 45px;
  /* Borders */
  border: 1px solid var(--matterColorNegative);
  border-radius: 0;

  &::placeholder {
    color: var(--matterColorAnti);
  }

  /* Effects */

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColorAnti);
    outline: none;
  }

  &:disabled {
    color: var(--matterColorAnti);
    border-color: var(--matterColorNegative);
    background-color: var(--matterColorNegative);
  }

  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 125%;
  }
}

textarea {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 15px;
  height: 45px;
  /* Borders */
  border: 1px solid var(--matterColorNegative);
  border-radius: 0;

  &::placeholder {
    color: var(--matterColorAnti);
  }

  /* Effects */

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColorAnti);
    outline: none;
  }

  &:disabled {
    color: var(--matterColorAnti);
    border-color: var(--matterColorAnti);
  }

  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 125%;
  }

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 2px 0 8px 0;

  &::placeholder {
    padding: 0 0 0 0;
  }

  @media (--viewportMedium) {
    padding: 6px 0 8px 0;

    &::placeholder {
      padding: 0 0 0 0;
    }
  }
}

.fontsLoaded {
  & body {
    font-family: 'Montserrat', sans-serif;
  }

  & button {
    font-family: 'Montserrat', sans-serif;
    border-radius: 6px;
  }
}

.backgroundImage {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('https://tutulist-product-photos.s3.us-east-2.amazonaws.com/tutulist-sharetribe/SectionHero3.jpg');

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  /* background-position: center center; */
}

.backgroundMobileImage {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('https://tutulist-product-photos.s3.us-east-2.amazonaws.com/tutulist-sharetribe/SectionHero3.jpg');

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  /* background-position: center center; */
}

/**
   * Usage example: .root { composes:  marketplaceDefaultFontStyles;  from global}
   */
.marketplaceDefaultFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }
}

.marketplaceSmallFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

/* ================ Body font ================ */

.marketplaceBodyFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

/* ================ Hero title ================ */

.marketplaceHeroTitleFontStyles {
  font-weight: var(--fontWeightBold);
  font-size: 26px;
  line-height: 26px;
  letter-spacing: -0.7px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 0;
  margin-bottom: 8px;

  @media (--viewportSmall) {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 14px;
  }

  @media (--viewportMedium) {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -1.5px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 0;
    margin-bottom: 23px;
  }
}

/* ================ Topbar ================ */

/* Compose text label items on top of this */
/* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
.TopbarDesktop_label {
  display: inline-block;
  margin: 20px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 20px 0;
  }
}

.TopbarDesktop_linkHover {
  transition: var(--transitionStyleButton);

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }
}

/* ================ Modal title ================ */

.marketplaceModalTitleStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    margin: 0;
  }
}

/* ================ H1, H2, H3, H4, H5 & H6 ================ */

.marketplaceH1FontStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 45px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.marketplaceH2FontStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;

  @media (--viewportMedium) {
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 21px;
    margin-bottom: 19px;
  }
}

.marketplaceH3FontStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.marketplaceH4FontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.marketplaceH5FontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 15px;
    line-height: 125%;
  }
}

.marketplaceH6FontStyles {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 6px;
  }
}

/* ================ Other fonts ================ */

.marketplaceTinyFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
}

.marketplaceMessageFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
}

.marketplaceMessageDateFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 12px;
    line-height: 24px;
  }
}

.marketplaceTxTransitionFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.marketplaceSearchFilterLabelFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.marketplaceSearchFilterSublabelFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 18px;
}

.marketplaceListingAttributeFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
}

/* ================ Tabbed navigation font styles ================ */

.marketplaceTabNavFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;
  }
}

.marketplaceTabNavHorizontalFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

/* ================ Clearfix solution ================ */

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/* ================ Links ================ */

.marketplaceLinkStyles {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

/* ================ Buttons ================ */

.marketplaceButtonFontStyles {
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    /* TODO: Make sure button text aligns with the baseline */
  }
}

.marketplaceButtonStyles {
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media (--viewportMedium) {
    /* TODO: Make sure button text aligns with the baseline */
  }

  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--matterColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }
}

.marketplaceButtonStylesPrimary {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--matterColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover,
  &:focus {
    background-color: var(--matterColorDark);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
  }
}

.marketplaceButtonStylesSecondary {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  line-height: 32px;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 10px 20px;

  @media (--viewportSmall) {
    display: inline-block;
    width: auto;
  }

  @media (--viewportMedium) {
    padding: 10px 20px;
  }

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: all var(--transitionStyleButton);
    outline: none;
    background-color: var(--matterColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  background-color: var(--matterColorLight);
  color: var(--matterColor);

  /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 10px 20px;

  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  transition: all ease 0.5s;

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    border-color: var(--matterColorAnti);
    transition: all ease 0.5s;
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    border-color: var(--matterColorNegative);
    transition: all ease 0.5s;
  }
}

/* ================ Inputs ================ */

.marketplaceInputStyles {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 15px;
  height: 45px;
  /* Borders */
  border: 1px solid var(--matterColorNegative);
  border-radius: 0;

  &::placeholder {
    color: var(--matterColorAnti);
  }

  /* Effects */

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    border-color: var(--matterColorAnti);
    outline: none;
  }

  &:disabled {
    color: var(--matterColorAnti);
    border-color: var(--matterColorAnti);
  }
}

.marketplaceInputFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 112%;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 125%;
  }
}

.marketplaceSelectStyles {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

  color: var(--marketplaceColorDark);
  /* Unset user agent styles */
  appearance: none;
  border: solid 1px var(--matterColorNegative);
  padding: 10px 15px;
  height: 45px;

  /* Borders */
  border-radius: 0;

  /* Background */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px;
  background-position: center right 15px;
  background-repeat: no-repeat;
  /* Effects */

  cursor: pointer;
  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {
    outline: none;
  }

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

/* ================ Modals ================ */

.marketplaceModalRootStyles {
  /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

  /* AuthenticationPage's root uses flexbox */
  display: flex;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
  }
}

.marketplaceModalFormRootStyles {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.marketplaceModalBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    /* min-height: 568px; */
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }
}

.marketplaceModalInMobileBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

/* Modal title */

.marketplaceModalTitleStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  line-height: 135%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
    line-height: 135%;
    letter-spacing: -0.9px;
    margin: 0;
  }
}

/* Modal message */

.marketplaceModalParagraphStyles {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
    letter-spacing: -0.11px;
    margin: 24px 0 0 0;
  }
}

/* Bottom wrapper for the modal */

.marketplaceModalBottomWrapper {
  text-align: center;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.marketplaceModalBottomWrapperText {
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.marketplaceModalHelperText {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }

  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.marketplaceModalHelperLink {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }

  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* Modal Close icon */

.marketplaceModalCloseStyles {
  /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  @media (max-width: 767px) {
    background-color: white !important;
    width: 100%;
    justify-content: flex-end;
  }

  @media (--viewportMedium) {
    padding: 27px 30px;
    position: absolute;
  }

  /* Colors */
  background-color: transparent;
  color: var(--matterColor);

  cursor: pointer;

  &:enabled:hover {
    background-color: transparent;
    color: var(--matterColorDark);
    box-shadow: none;
    text-decoration: none;
  }

  &:enabled:active {
    background-color: transparent;
    color: var(--matterColorDark);
  }

  &:disabled {
    background-color: transparent;
  }
}

.marketplaceModalIconStyles {
  height: 48px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.marketplaceModalCloseText {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 6px;
  }

  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.marketplaceModalCloseIcon {
  display: inline-block;
  margin-left: 8px;

  /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;

  @media (--viewportMedium) {
    margin-top: 2px;
  }
}

.marketplaceModalErrorStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }

  margin-top: 24px;
  color: var(--failColor);
}

.marketplaceModalPasswordMargins {
  /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
