@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
}

.logoutButton {
  composes: marketplaceLinkStyles from global;
  composes: marketplaceH4FontStyles from global;
  color: var(--matterColorDark);
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
}

.userAccount {
  display: flex;
  align-items: center;

  & .avatar {
    flex-shrink: 0;
    width: 56px;
    height: 56px;

    @media (--viewportSmall) {
      width: 96px;
      height: 96px;
    }
  }

  & .rightSec {
    padding: 0 0 0 16px;
  }
}

.logoutButton {
  composes: marketplaceLinkStyles from global;
  composes: marketplaceH4FontStyles from global;
  color: var(--matterColorAnti);
  width: initial;
  margin: 0;
  padding: 0;

  &:hover {
    color: var(--matterColorDark);
  }
}

.greetingSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.greeting {
  composes: marketplaceH2FontStyles from global;
  margin-bottom: 12px;
  margin-top: 0px;
}

.inbox {
  composes: marketplaceH2FontStyles from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
  font-size: 16px;
}

.navigationLink {
  composes: marketplaceH2FontStyles from global;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 16px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 2px;
  right: -31px;

  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: marketplaceButtonStyles from global;
  margin-right: 8px;
}

.authenticationGreeting {
  composes: marketplaceH1FontStyles from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  composes: marketplaceH4FontStyles from global;
  margin: 0 0 5px 0;
  font-weight: 600;
  color: var(--matterColor);
  text-decoration: none;

  &:hover {
    color: var(--matterColor);
    text-decoration: none;
  }
}

.loginLink {
  composes: marketplaceH4FontStyles from global;
  margin: 0 0 10px 0;
  font-weight: 600;
  color: var(--matterColor);
  text-decoration: none;

  &:hover {
    color: var(--matterColor);
    text-decoration: none;
  }
}

.currentPage {
  color: var(--matterColorDark);
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.links {
  & .linkItem {
    padding: 5px 10px;
    color: var(--matterColorDark);
    text-decoration: none;
    width: 100%;
    display: inline-block;
    font-weight: var(--fontWeightMedium);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;

      & > span {
        & > svg {
          fill: var(--marketplaceColor);
        }
      }
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > svg {
        width: 13px;
      }
    }

    & > a {
      color: var(--matterColorDark);
      text-decoration: none;

      &:hover {
        color: var(--matterColorDark);
        text-decoration: none;
      }
    }
  }
}

.browseMenu,
.moreFrom {
  width: 100%;
  margin: 10px 0;

  & > h2 {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }

    font-size: 16px;
    margin: 0 0 5px 0;
    font-weight: 600;
    color: var(--marketplaceColor);
  }
}

.drawerMenu {
  padding: 20px;
  margin: 0;

  & .links {
    & .linkItem {
      padding: 10px 0px;
    }
  }
}

.drawerHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  & .back {
    & > svg {
      transform: rotate(180deg);
      width: 20px;

      &:hover {
        & > path {
          fill: var(--marketplaceColor);
        }
      }
    }
  }

  & .cross {
    font-size: 35px;
    font-weight: 400;
    color: var(--matterColor);

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.socialMediaLinks {
  margin-bottom: 10px;

  & > a {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    & > svg {
      & > path {
        fill: var(--matterColor);
      }
    }
  }
}

.community {
  margin: 0 0 5px 0;
  font-weight: 600;
  color: var(--marketplaceColor);
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
