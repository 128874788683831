@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border: none;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: all ease 0.5s;

    & .aspectRatioWrapper {
      transition: all ease 0.5s;

      @media (--viewportMedium) {
        & img {
          transform: scale(1.2);
          transition: all ease 0.5s;
        }
      }
    }
  }

  & .aspectRatioWrapper {
    transition: all ease 0.5s;
    background: var(--matterColorNegative);
    border-radius: 10px;
    overflow: hidden;
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & img {
      transform: scale(1);
      transition: all ease 0.5s;
    }
  }
}

.cardImg {
  position: relative;
  padding-bottom: 150%;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.authorBlock {
  display: flex;
  align-items: center;

  & .authorInfo {
    padding-left: 8px;
  }

  & .authorImg {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    /* border: solid 1px var(--matterColorNegative); */
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > a {
      & > span {
        font-size: 10px;
      }
    }
  }
}

.cardItemInfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  & .saves {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    & > span {
      cursor: pointer;

      & > img {
        margin-right: 5px;
        width: 18px;
      }
    }

    & svg {
      color: #4a4a4a;
      width: 18px;
      cursor: pointer;

      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }

  & .favorite {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      color: #4a4a4a;
      width: 18px;
      cursor: pointer;

      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }
}

.saves {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media (max-width: 375px) {
    font-size: 13px;
  }

  & > span {
    cursor: pointer;

    & > img {
      margin-right: 5px;
      width: 18px;
    }
  }

  & svg {
    color: #4a4a4a;
    width: 18px;
    cursor: pointer;

    @media (max-width: 375px) {
      width: 14px;
    }

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorLight);
  width: 30px;
  height: 30px;
  border-radius: 100px;

  @media (max-width: 375px) {
    width: 24px;
    height: 24px;
  }

  & > span {
    position: relative;
    bottom: 1px;
  }

  & svg {
    color: #4a4a4a;
    width: 18px;
    cursor: pointer;

    @media (max-width: 375px) {
      width: 14px;
    }

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.cardTopInfo {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 10px 8px;
  background: white;
  & > .authorBlock {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColor);
    font-weight: 600;
    padding: 4px 10px 0px 0px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;

    @media (max-width: 375px) {
      padding: 4px 6px;
    }

    & .dotIcon {
      width: 5px;
      height: 5px;
      border-radius: 100px;
      background-color: var(--marketplaceColor);
      display: inline-block;
      margin-right: 5px;

      @media (max-width: 375px) {
        margin-right: 3px;
        width: 4px;
        height: 4px;
      }
    }

    & span {
      font-size: 13px;
      line-height: 100%;
      position: relative;
      bottom: 1px;
      color: var(--matterColor);

      @media (max-width: 375px) {
        font-size: 12px;
      }
    }

    & .authorInfo {
      padding: 0;
    }
  }

  & .cardTopInfoLeft {
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 6px;
  border-radius: 0px;
  position: relative;
  height: 100%;
  background: white;
}

.fees {
  color: var(--marketplaceColorDark);
  font-weight: 500;

  & > span {
    color: var(--marketplaceColorDark);
  }
}

.price {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-start;
}

.priceValue {
  composes: marketplaceH3FontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  justify-content: space-between;
}

.title {
  composes: marketplaceDefaultFontStyles from global;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 4px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-right: 10px;
  min-height: 36px;

  @media (max-width: 375px) {
    font-size: 12px;
    line-height: 16px;
    min-height: 30px;
  }
}

.richText {
  font-weight: 700;
  color: var(--marketplaceColor) !important;
}

.productPrice {
  font-size: 16px;
  font-weight: 700;
  color: var(--marketplaceColor) !important;
}

.authorInfo {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 375px) {
    font-size: 13px;
  }
}

.longWord {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.filled {
  fill: var(--marketplaceColor);

  & > path {
    fill: var(--marketplaceColor);
    stroke: none;
  }
}

.firstPhoto {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    object-fit: contain;
    max-width: 100%;
    /* width: 296px;
    height: 334px;
    object-fit: cover;

    @media only screen and (min-width: 300px) and (max-width: 375px) {
      width: 273px;
      height: 343px;
      object-fit: cover;
    }

    @media only screen and (min-width: 375px) and (max-width: 425px) {
      width: 327px;
      height: 380px;
      object-fit: cover;
    }

    @media only screen and (min-width: 425px) and (max-width: 768px) {
      width: 383px;
      height: 383px;
      object-fit: cover;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 235px;
      height: 295px;
      object-fit: cover;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 211px;
      height: 283px;
      object-fit: cover;
    }

    @media (min-width:1440px) {
      width: 293px;
      height: 343px;
      object-fit: cover;
    } */
  }
}

.firstPhotoProfile {
  height: 100%;
  width: 100%;

  & > img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.cardInfo {
  display: flex;
  justify-content: space-between;
}
