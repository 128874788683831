@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-radius: 10px;
  background-color: var(--matterColorLight);
  border: solid 1px var(--matterColorNegative);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: all ease 0.5s;
    box-shadow: var(--boxShadowListingCard);

    & .aspectRatioWrapper {
      transition: all ease 0.5s;

      @media (--viewportMedium) {
        & img {
          transform: scale(1.2);
          transition: all ease 0.5s;
        }
      }
    }
  }

  & .aspectRatioWrapper {
    transition: all ease 0.5s;
    background: var(--matterColorNegative);
    border-radius: 10px;
    overflow: hidden;
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & img {
      transform: scale(1);
      transition: all ease 0.5s;
    }
  }
}

.cardImg {
  position: relative;
  padding-bottom: 150%;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .authorBlock {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--matterColorLight);
    color: var(--marketplaceColor);
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 6px;
    z-index: 1;
    display: inline-block;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    & span {
      font-size: 13px;
      position: relative;
      bottom: 1px;
      color: var(--marketplaceColorDark);
    }

    & .authorInfo {
      padding: 0;
    }
  }
}

.authorBlock {
  display: flex;
  align-items: center;

  & .authorInfo {
    padding-left: 8px;
  }

  & .authorImg {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > a {
      & > span {
        font-size: 10px;
      }
    }
  }
}

.cardItemInfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  & .saves {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    & > span {
      cursor: pointer;

      & > img {
        margin-right: 5px;
        width: 18px;
      }
    }

    & svg {
      color: #4a4a4a;
      width: 18px;
      cursor: pointer;

      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }

  & .favorite {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      color: #4a4a4a;
      width: 18px;
      cursor: pointer;

      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }
}

.saves {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  & > span {
    cursor: pointer;

    & > img {
      margin-right: 5px;
      width: 18px;
    }
  }

  & svg {
    color: #4a4a4a;
    width: 22px;
    cursor: pointer;
    position: relative;
    bottom: 2px;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    color: #4a4a4a;
    width: 20px;
    cursor: pointer;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.cardTopInfo {
  padding: 10px;
  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 10px;
  }

  & .cardTopInfoLeft {
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 14px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  position: relative;
  justify-content: space-between;
  height: 100%;
}

.price {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-start;
  line-height: 0;
}

.priceValue {
  composes: marketplaceH3FontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.perUnit {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
}

.title {
  composes: marketplaceDefaultFontStyles from global;
  line-height: 18px;
  font-size: 15px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 4px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-right: 10px;
  font-weight: 700;
}

.authorInfo {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.longWord {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  font-weight: 700;
}

.filled {
  fill: var(--marketplaceColor);

  & > path {
    fill: var(--marketplaceColor);
    stroke: none;
  }
}

.firstPhoto {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    object-fit: contain;
    max-width: 100%;
    /* width: 296px;
    height: 334px;
    object-fit: cover;

    @media only screen and (min-width: 300px) and (max-width: 375px) {
      width: 273px;
      height: 343px;
      object-fit: cover;
    }

    @media only screen and (min-width: 375px) and (max-width: 425px) {
      width: 327px;
      height: 380px;
      object-fit: cover;
    }

    @media only screen and (min-width: 425px) and (max-width: 768px) {
      width: 383px;
      height: 383px;
      object-fit: cover;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 235px;
      height: 295px;
      object-fit: cover;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 211px;
      height: 283px;
      object-fit: cover;
    }

    @media (min-width:1440px) {
      width: 293px;
      height: 343px;
      object-fit: cover;
    } */
  }
}

.firstPhotoProfile {
  height: 100%;
  width: 100%;

  & > img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.cardInfo {
  display: flex;
  justify-content: space-between;
}

.authorName {
  color: var(--marketplaceColor);
}
