@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}

.error {
  composes: marketplaceModalErrorStyles from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px !important;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  composes: marketplaceH3FontStyles from global;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
  margin-bottom: 30px;
}

@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  border: 1px solid var(--matterColorNegative);
  margin-bottom: 24px;
  padding: 10px 15px;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}

.submitButtonMaybe {
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 14px;
}

.editListingStripeAccount {
  & .savedInformation {
    & .accountInformationTitle {
      padding: 0;
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    & .savedCountry {
      padding: 0;
    }

    & .savedBankAccount {
      margin-top: 10px;
    }
  }
}

.backButton {
  position: absolute;
  font-size: 14px;
  padding: 10px 10px;
  background-color: transparent;
  color: var(--matterColorAnti);
  line-height: 100%;
  top: 16px;
  left: 10px;
  z-index: 111;
  width: auto;
  height: auto;

  @media (--viewportMedium) {
    top: 20px;
    left: 50px;
  }

  &:hover {
    color: var(--matterColor);
    background-color: transparent;
  }

  & > svg {
    width: 16px;
    height: 16px;
  }
}
