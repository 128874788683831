@font-face {
  font-family: 'revicons';
  fallback: fallback;
  src: url('./revicons.woff') format('woff'), url('./revicons.ttf') format('ttf'),
    url('./revicons.eot') format('ttf');
}
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgb(255 255 255) !important;
  min-width: 35px;
  min-height: 35px;
  opacity: 1;
  border-radius: 50% !important;
  cursor: pointer;
}
.react-multiple-carousel__arrow:hover {
  background: rgb(255 255 255) !important;
}
.react-multiple-carousel__arrow::before {
  font-size: 16px;
  color: #3d3d3d !important;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: #2f2f2f !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
  display: none;
}
.react-multiple-carousel__arrow--left::before {
  content: '\e824';
  display: none;
}
.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
  display: none;
}
.react-multiple-carousel__arrow--right::before {
  content: '\e825';
  display: none;
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.react-multi-carousel-dot button:hover:active {
  background: #59766c;
}
.react-multi-carousel-dot--active button {
  background: #59766c;
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}
[dir='rtl'].react-multi-carousel-list {
  direction: rtl;
}
.rtl.react-multiple-carousel__arrow--right {
  right: auto;
  left: calc(4% + 1px);
}
.rtl.react-multiple-carousel__arrow--right::before {
  content: '\e824';
}
.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(4% + 1px);
}
.rtl.react-multiple-carousel__arrow--left::before {
  content: '\e825';
}
