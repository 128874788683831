@import '../../styles/customMediaQueries.css';

.searchItemsContainer {
  display: flex;
  align-items: center;

  @media (--viewportLarge) {
    border-radius: 10px;
    border: solid 1px var(--matterColorNegative);
    padding: 0px 7px 0px 0px;
    height: 45px;
  }

  & .keywordSearch {
    margin-right: 10px;
    width: calc(100% - 30px);
    order: 2;

    @media (--viewportLarge) {
      order: 1;
    }

    & .field {
      & > input {
        border: none;
        border-radius: 0;
        height: auto;
        padding: 0;

        @media (--viewportLarge) {
          padding: 10px 15px;
        }
      }
    }
  }

  & .button {
    border: none;
    transition: all ease 0.5s;
    border-radius: 110px;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;

    @media (--viewportLarge) {
      color: var(--matterColorLight);
      background-color: var(--marketplaceColor);
      order: 2;
    }

    &:hover {
      cursor: pointer;

      @media (--viewportLarge) {
        background-color: var(--matterColorDark);
        color: var(--matterColorLight);
      }
    }

    & > svg {
      width: 16px !important;

      & path {
        fill: var(--matterColorDark);

        @media (--viewportLarge) {
          fill: var(--matterColorLight);
        }
      }
    }
  }
}

.errorPageSearch {
  & .searchItemsContainer {
    border: none;
    height: auto;
    padding: 0;
    border-radius: 0;
    align-items: initial;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    & .keywordSearch {
      border: solid 1px var(--matterColorNegative);
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      order: 1;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;

      @media (--viewportSmall) {
        margin-right: 10px;
        margin-bottom: 0px;
        width: calc(100% - 60px);
      }

      & > svg {
        & path {
          fill: var(--matterColorAnti);
        }
      }

      & .field {
        width: calc(100% - 20px);

        & > input {
          padding: 10px 15px;
        }
      }
    }

    & .button {
      width: auto;
      height: auto;
      font-weight: 600;
      border-radius: 6px;
      padding: 10px 20px;
      order: 2;
      color: var(--matterColorLight);
      background-color: var(--marketplaceColor);

      @media (--viewportSmall) {
        padding: 8px 20px;
      }
    }
  }
}

.landingHeroSearch {
  & .searchItemsContainer {
    border: none;
    height: auto;
    padding: 0;
    border-radius: 0;
    align-items: initial;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    & .keywordSearch {
      border: solid 1px var(--matterColorLight);
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0px;
      order: 1;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
      background-color: #fff;

      @media (--viewportSmall) {
        margin-right: 10px;
        margin-bottom: 0px;
        width: calc(100% - 60px);
      }

      @media (--viewportMedium) {
        border: solid 1px var(--matterColorNegative);
      }

      & > svg {
        & path {
          fill: var(--matterColorAnti);
        }
      }

      & .field {
        width: calc(100% - 20px);

        & > input {
          padding: 10px 15px;
        }
      }
    }

    & .button {
      width: auto;
      height: auto;
      font-weight: 600;
      border-radius: 6px;
      padding: 10px 20px;
      order: 2;
      color: var(--matterColorLight);
      background-color: var(--marketplaceColor);

      @media (--viewportSmall) {
        padding: 8px 20px;
      }

      &:hover {
        background-color: var(--matterColorDark);
      }
    }
  }
}

.searchKeywords {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;

  & > .pills {
    background-color: var(--matterColorBright);
    border: solid 1px var(--matterColorNegative);
    padding: 2px 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 24px;
    border-radius: 100px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.5s;

    &:hover {
      background-color: var(--matterColorLight);
      border: solid 1px var(--marketplaceColor);
      color: var(--marketplaceColor);
      transition: all ease 0.5s;
    }
  }
}
