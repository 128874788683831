@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  position: relative;
  width: 100%;
  padding-right: 15px;

  &:hover {
    text-decoration: none;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
}

.productDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  & > * {
    width: calc(100% / 3);
  }

  & .price {
    text-align: right;

    @media (min-width: 991px) {
      text-align: right;
    }

    & .priceValue {
      font-weight: var(--fontWeightSemiBold);
      font-size: 18px;
      line-height: 24px;
      /* margin-top + n * line-height + margin-bottom => x * 6px */
      margin-top: 16px;
      margin-bottom: 14px;

      @media (--viewportMedium) {
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 16px;
        margin-bottom: 16px;
      }

      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & .perUnit {
      font-family: var(--fontFamily);
      font-weight: var(--fontWeightRegular);
      font-size: 13px;
      line-height: 18px;
      /* margin-top + n * line-height + margin-bottom => x * 6px */
      margin-top: 9.5px;
      margin-bottom: 8.5px;
      -webkit-font-smoothing: subpixel-antialiased;

      @media (--viewportMedium) {
        line-height: 16px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 10.5px;
        margin-bottom: 13.5px;
      }

      color: var(--matterColor);
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .productDetailsLeft {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 991px) {
      margin-bottom: 10px;
    }

    & .productImg {
      & .aspectRatioWrapper {
        transition: all ease 0.5s;
        background: var(--matterColorNegative);
        border-radius: 10px;
        overflow: hidden;
        overflow: hidden;
        width: 100px;
        height: 100px;

        @media (--viewportMedium) {
          &:hover {
            transition: all ease 0.5s;
            box-shadow: var(--boxShadowListingCard);

            & img {
              transform: scale(1.05);
              transition: all ease 0.5s;
            }
          }
        }

        & img {
          transform: scale(1);
          transition: all ease 0.5s;
        }
      }
    }

    & .info {
      padding-left: 15px;

      & .mainInfo {
        display: flex;
        flex-direction: column;

        & .title {
          font-family: var(--fontFamily);
          font-weight: var(--fontWeightMedium);
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          /* No margins for default font */

          @media (--viewportMedium) {
            font-size: 16px;
            line-height: 32px;
          }

          line-height: 24px;
          color: var(--matterColor);
          margin-top: 0;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 16px;

          @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
          }

          & .longWord {
            word-break: break-all;
            word-break: break-word;
            hyphens: auto;
          }
        }

        & .authorInfo {
          font-family: var(--fontFamily);
          font-weight: var(--fontWeightRegular);
          font-size: 13px;
          line-height: 18px;
          /* margin-top + n * line-height + margin-bottom => x * 6px */
          margin-top: 9.5px;
          margin-bottom: 8.5px;
          -webkit-font-smoothing: subpixel-antialiased;

          @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10.5px;
            margin-bottom: 13.5px;
          }

          color: var(--matterColor);
          padding-top: 4px;
          padding-bottom: 4px;
          margin-top: 0;
          margin-bottom: 0;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;

          @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      & .favorite {
        cursor: pointer;
        font-size: 12px;
        color: var(--matterColorDark);

        &:hover {
          color: var(--marketplaceColor);
        }

        &:hover {
          & svg {
            color: var(--marketplaceColor);
          }
        }

        & svg {
          margin-top: -2px;
          margin-left: -4px;
          color: var(--matterColorDark);
          cursor: pointer;

          &:hover {
            color: var(--marketplaceColor);
          }

          &.deleteIcon {
            margin-left: 0;
          }
        }
      }
    }
  }

  & .productDetailsRight {
    display: flex;

    & .stock {
      display: flex;
      align-items: center;

      & > button {
        padding: 0 10px;
        background-color: transparent;
        color: #333;
        outline: none;
        box-shadow: none;
        line-height: 12px;
        height: 25px;
        border: none;
        cursor: pointer;

        & svg {
          width: 16px;
          cursor: pointer;
        }

        &:hover {
          color: var(--marketplaceColor);
        }
      }

      & .stockCount {
        color: var(--matterColorDark);
        font-size: 14px;
        border: solid 1px #ddd;
        line-height: 20px;
        width: 26px;
        height: 26px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        padding: 0 10px;
      }
    }
  }
}

.filled {
  fill: var(--marketplaceColor);
}

.firstPhoto {
  & > img {
    width: 101px;
    height: 101px;
  }
}
