.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 24px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  color: var(--matterColor);
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  margin-bottom: 14px;
  margin-top: 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: marketplaceH4FontStyles from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
.submitButton {
  background-color: var(--marketplaceColor);
  border: solid 1px var(--marketplaceColor);
  color: var(--matterColorLight);
  width: 100%;
  font-size: 16px;
  line-height: 150%;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
  &:hover {
    background-color: var(--marketplaceColorDark);
    border: solid 1px var(--marketplaceColorDark);
  }
}

.pricePackageSec {
  display: flex;
  flex-direction: column;
  & > label {
    font-size: 16px;
    font-weight: var(--fontWeightBold);
    color: var(--matterColorDark);
    margin: 0 0 8px 0;
  }
  & .pricingOptions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin: 0 0 16px 0;
    & .price {
      background: rgb(251 251 251);
      border-radius: 8px;
      padding: 10px;
      & .priceValue {
        font-size: 16px;
        font-weight: var(--fontWeightSemiBold);
        color: var(--matterColorDark);
        line-height: 120%;
      }
      & .priceDuration {
        font-size: 14px;
        font-weight: var(--fontWeightMedium);
        color: var(--matterColor);
        line-height: 120%;
      }
    }

    & .selectedPrice {
      background: whitesmoke;
      border: 1px solid var(--marketplaceColor);
      border-radius: 4px;
      padding: 6px;
      font-size: 16px;
    }
  }
}
