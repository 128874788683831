@import '../../styles/customMediaQueries.css';

.sectionTitle {
  font-size: 20px;
  font-weight: 600;
  color: var(--matterColorDark);
  margin: 0;
}

.profileSliderButtons {
  position: absolute;
  top: 40%;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & .iconHolder {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer;

    @media (--viewportXXLarge) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      border-color: #484848;
      cursor: pointer;
    }

    &:first-child {
      margin-right: 4px;

      @media (--viewportXXLarge) {
        margin-right: 14px;
      }
    }

    & svg {
      fill: none;
    }
  }
}

.homepage {
  width: calc(100% / 3 - 15px);
  margin-bottom: 15px;
  margin-right: 15px;
  scroll-snap-align: start;

  &:last-child {
    margin-right: 0px;

    & > a {
      margin-right: 0px;
    }
  }
}

.moreServices {
  & .moreServicesTitle {
    margin-bottom: 15px;

    & h1 {
      font-style: normal;
      font-weight: bold;
      color: #1f1f1f;
      font-size: 25px;
      line-height: 100%;
      letter-spacing: -0.04em;
      margin: 0px 0px 16px 0;

      @media (min-width: 480px) {
        font-size: 36.6667px;
        line-height: 100%;
      }

      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 100%;
      }

      @media (min-width: 1260px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width: 1366px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width: 1660px) {
        font-size: 36.8889px;
        line-height: 100%;
      }

      @media (min-width: 1921px) {
        font-size: 56.8956px;
        line-height: 100%;
      }
    }

    & .sliderButtons {
      display: none;

      @media (--viewportMedium) {
        display: block;
      }

      & .iconHolder {
        background-color: #fff;
        border: 1px solid #ebebeb;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.5s;

        @media (--viewportXXLarge) {
          width: 56px;
          height: 56px;
        }

        &:hover {
          border-color: #484848;
          cursor: pointer;
        }

        &:first-child {
          margin-right: 4px;

          @media (--viewportXXLarge) {
            margin-right: 14px;
          }
        }

        & svg {
          fill: none;
        }
      }
    }
  }

  & .recentlyAddedSlider {
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;

      & .homepage {
        min-width: 260px;
        margin-right: 15px;
        scroll-snap-align: start;

        &:last-child {
          margin-right: 0px;

          & > a {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

.topCategorySection {
  & .cardSlider {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 0;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    display: grid;
    grid-gap: 15px;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & .cardSliderItem {
      width: 100%;

      & > a {
        &:hover {
          text-decoration: none;
          transition: all ease 0.5s;

          & .imgWrapper {
            transition: all ease 0.5s;

            & > img {
              transform: scale(1.05);
              transition: all ease 0.5s;
            }
          }

          & .cardItemTitle {
            color: var(--marketplaceColor);
          }
        }

        & .makesImage {
          /* border-radius: 10px; */
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          /* border: solid 1px var(--matterColorNegative); */
          overflow: hidden;
          transition: all ease 0.5s;
          border-radius: 10px;
          width: 100%;

          & > img {
            object-fit: cover;
            max-width: 100%;
            width: 100%;
            transform: scale(1);
            transition: all ease 0.5s;
          }
        }

        & .cardItemTitle {
          font-size: 15px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 0;
          color: #000;
          text-align: center;
        }
      }
    }
  }
}

.brandingSection {
  & .cardSlider {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 0;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    display: grid;
    grid-gap: 15px;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);

    @media (--viewportSmall) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & .cardSliderItem {
      width: 100%;

      & > a {
        &:hover {
          text-decoration: none;
          transition: all ease 0.5s;

          & .imgWrapper {
            transition: all ease 0.5s;

            & > img {
              transform: scale(1.05);
              transition: all ease 0.5s;
            }
          }

          & .cardItemTitle {
            color: var(--marketplaceColor);
          }
        }

        & .makesImage {
          /* border-radius: 10px; */
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          /* padding: 20px; */
          /* border: solid 1px #ececec; */
          overflow: hidden;
          transition: all ease 0.5s;
          border-radius: 10px;
          width: 100%;

          & > img {
            object-fit: cover;
            max-width: 100%;
            width: 100%;
            transform: scale(1);
            transition: all ease 0.5s;
          }
        }

        & .cardItemTitle {
          font-size: 15px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 0;
          color: #000;
          text-align: center;
        }
      }
    }
  }
}

.moreServicesTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (--viewportSmall) {
    padding: 0;
  }

  & > h1 {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.12px;
  }
}

.authors {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.makesImage {
  & > img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.header {
  display: flex;
}

.titleHighlight {
  color: var(--marketplaceColor);
}
