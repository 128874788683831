@import '../../../styles/customMediaQueries.css';

.quantityField {
  padding: 0 24px;
  margin-bottom: 24px;
  float: left;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  float: left;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }

  & > label {
    margin-top: 0;
    padding: 0;
    margin-bottom: 12px;
    font-size: 20px;
    color: var(--matterColorDark);
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;
  float: left;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 24px;
  }

  & > h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 20px;
    color: var(--matterColorDark);
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.error {
  composes: marketplaceH4FontStyles from global;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.bookingPanelButtons {
}
.productOrderButton {
  display: flex;
  flex-direction: column;
  & > button {
    width: 100%;
    font-size: 16px;
    line-height: 150%;
    font-weight: var(--fontWeightSemiBold);
    margin-bottom: 16px;
    float: left;

    &:last-child {
      margin-bottom: 0;
    }
  }
  & .submitButton {
    background-color: var(--marketplaceColor);
    border: solid 1px var(--marketplaceColor);
    color: var(--matterColorLight);
    &:hover {
      background-color: var(--marketplaceColorDark);
      border: solid 1px var(--marketplaceColorDark);
    }
  }
  & .makeOfferButton {
    background-color: transparent;
    border: solid 1px var(--matterColorDark);
    color: var(--matterColorDark);
    &:hover {
      background-color: var(--matterColorDark);
      border: solid 1px var(--matterColorDark);
      color: var(--matterColorLight);
    }
  }
}
