@import '../../styles/customMediaQueries.css';

.root {
}

.input {
  border: solid 1px var(--matterColorNegative);
  padding: 10px 15px;
  height: 45px;
  border-radius: 4px;
}

.inputSuccess {
  border-color: var(--matterColorNegative);
}

.inputError {
  border-color: var(--failColor);
}

.textarea {
  height: 150px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
}
