@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;
    width: 100%;
    padding-top: 30px;
    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      max-width: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }

    @media (--viewportMedium) {
      max-width: 640px;
      min-height: auto;
      height: auto;
      box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
      border-radius: 12px;
    }
  }
}

.fullWidthModal {
  background-color: var(--matterColorLight);

  & .scrollLayer {
    background-color: var(--matterColorLight);
  }
}

.mobileMenuOpen {
  & .container {
    flex-basis: 100%;
    margin-top: 0;
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: absolute;
  top: 0;
  left: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}

.socialSharingModal {
  & .container {
    padding: 24px;
  }

  & .modalHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0px;

    & > h1 {
      margin: 0;
      color: var(--matterColorDark);
      font-size: 24px;
      line-height: 130%;
    }

    & .close {
      position: relative;
      padding: 0;
      margin-bottom: 20px;

      & > svg {
        margin: 0;
      }
    }
  }
}
.offerModal,
.gallaryModal {
  & .scrollLayer {
    & .container {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
  }
}
.gallaryModal {
  & .scrollLayer {
    & .container {
      padding: 16px;
      @media (--viewportMedium) {
        padding: 24px;
      }
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        & .content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex-grow: 1;
          & :global(.slick-slider) {
            width: 100%;
          }
        }
      }
      & .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        & .close {
          position: relative;
          display: flex;
          justify-content: flex-end;
          padding: 0;
          width: auto;
          order: 2;
        }
        & > h1 {
          order: 1;
          font-size: 18px;
          color: var(--matterColorDark);
          margin: 0;
          line-height: 120%;
          flex-grow: 1;
          @media (--viewportMedium) {
            font-size: 20px;
          }
        }
      }
      & .content {
        & :global(.slick-slider) {
          position: relative;
        }
        & :global(.slick-dots) {
          position: relative;
          bottom: 0;
        }
        & :global(.slick-dots > li) {
          width: 64px;
          height: 64px;
          margin: 0;
        }
        & :global(.slick-dots > li > div) {
          border-radius: 10px;
          overflow: hidden;
          border: solid 2px var(--matterColorLight);
        }
        & :global(.slick-dots > li.slick-active > div) {
          border: solid 2px var(--marketplaceColor);
        }

        & :global(.slick-arrow) {
          width: 40px;
          height: 40px;
          z-index: 1;
          top: auto;
          bottom: 0;
        }
        & :global(.slick-arrow > svg) {
          border: solid 1px rgba(232, 232, 232, 1);
          border-radius: 100px;
          width: 100%;
          height: 100%;
          transition: var(--transitionStyle);
        }
        & :global(.slick-arrow:hover > svg) {
          transition: var(--transitionStyle);
          border-color: var(--marketplaceColor);
        }
        & :global(.slick-arrow:hover > svg > path) {
          stroke: var(--marketplaceColor);
          transition: var(--transitionStyle);
        }
        & :global(.slick-arrow::before) {
          content: '';
        }
        & :global(.slick-prev) {
          left: 0;
        }
        & :global(.slick-next) {
          right: 0;
        }
      }
    }
  }
}
