.sliderButtons {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;
  padding: 0px 0px 0px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (--viewportSmall) {
    top: 0;
  }
  & .exploreBtn {
    margin-right: 10px;
    text-decoration: none;
    &:hover {
      color: var(--matterColorDark);
    }
  }
  & .iconHolder {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer;
    margin-right: 5px;

    @media (max-width: 325px) {
      width: 22px;
      height: 22px;
    }

    @media (max-width: 375px) {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 425px) {
      width: 28px;
      height: 28px;
    }
    @media (--viewportXXLarge) {
      width: 56px;
      height: 56px;
    }
    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      border-color: var(--marketplaceColor);
      cursor: pointer;

      & > svg {
        & > circle {
          background-color: var(--matterColorLight);
        }

        & > path {
          stroke: var(--marketplaceColor);
        }
      }
    }

    &:first-child {
      margin-right: 4px;

      @media (--viewportXXLarge) {
        margin-right: 14px;
      }
    }

    & svg {
      fill: none;
    }
  }
}
.sliderContainer {
  padding: 0px 16px;
  margin: -60px -16px 0;
  @media (--viewportMedium) {
    margin: -60px -24px 0;
    padding: 0px 24px;
  }
  & :global(.react-multi-carousel-list) {
    width: 100%;
  }
  & :global(.react-multi-carousel-track) {
    margin-top: 60px;
  }
}
