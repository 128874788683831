@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  flex-grow: 1;
  display: flex;
  background-color: var(--matterColorLight);
}

.errorPageWrapper {
  display: flex;
  max-width: calc(1200px + 48px);
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.content {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;

  @media (--viewportMedium) {
    padding-right: 2%;
    width: 50%;
  }
}

.errorImg {
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    width: 50%;
  }

  & > img {
    max-width: 100%;
  }
}

.number {
  font-size: 15px;
  color: var(--matterColor);
}

.heading {
  font-size: 28px;
  line-height: 100%;
  color: var(--matterColorDark);
  margin-top: 10px;

  @media (--viewportMedium) {
    font-size: 30px;
  }

  @media (--viewportLarge) {
    font-size: 38px;
  }
}

.description {
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 150%;
  color: var(--matterColor);
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}

.filterSearchBox {
}
