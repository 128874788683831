@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 80px 0 80px 36px;
    flex-direction: column;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 80px 0 80px calc((100% - 1056px) / 2);
  }
}

.rootEditListing {
  /* Layout */
  display: none !important;
  flex-direction: row;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 80px 0 80px 36px;
    flex-direction: column;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 80px 0 80px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  min-width: 200px;

  @media (--viewportMedium) {
    min-height: 56px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
