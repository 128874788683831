@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  /* Font */
  composes: marketplaceTabNavFontStyles from global;
  font-size: 15px;
  line-height: 100%;
  padding: 15px 0px;
  color: var(--matterColor);
  margin-top: auto;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }
  & > span {
    display: inline-flex;
    align-items: center;
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--marketplaceColor);

  @media (max-width: 768px) {
    border-bottom: 1px solid #000;
    color: #c6488c !important;
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

.inboxTabs {
  & .link {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #6f767e;
    transition: all 0.2s;

    &:hover {
      color: var(--matterColorDark);
    }

    &.selectedLink {
      background-color: #efefef;
      color: var(--matterColorDark) !important;
      border: none;
    }
  }
}
